import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  useRadioGroup,
  HStack,
  Box,
  Center,
  Image,
  Text,
  useToast,
  Input,
  FormLabel,
  Spinner,
} from '@chakra-ui/react';

import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from '../../store';
import RadioCard from '../RadioCard';
import { Discount, Plan } from './PricingPlans';
import usePaymentState from '../../hooks/usePaymentState';
import { greenishColor } from '../../utils/theme';
import PaymobLogo from '../../assets/paymob.png';
import { useMutation } from '@apollo/client';
import { CREATE_PAYMENT_ORDER } from '../../utils/mutations';
import { isValidPhoneNumber } from '../../utils/phoneUtil';
import { PaymentData, PaymentMethods } from '../../utils/types';
import { generatePayment } from '../../utils/api';

const PaymentMethodsPopup = ({
  selectedPlan,
  compName,
  discountData,
}: {
  compName: string | undefined;
  selectedPlan: Plan | undefined;
  discountData?: Discount | undefined;
}) => {
  const toast = useToast();
  const { error, playerInfo, confirmation, currentParticipation, amountToPay } =
    usePaymentState(compName, selectedPlan, discountData);
  const [createPayment] = useMutation(CREATE_PAYMENT_ORDER);
  const { popupsModel: popupsState } = useStoreState((state) => state);
  const { popupsModel: popupsActions } = useStoreActions((state) => state);
  const [selectedMethod, setSelectedMethod] = useState('Card');
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState('');

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'Card',
    defaultValue: 'Card',
    onChange: setSelectedMethod,
  });

  const group = getRootProps();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  useEffect(() => {
    if (popupsState.showPlanPopupChecker) {
      onOpen();
    }
  }, [onOpen, popupsState.showPlanPopupChecker]);

  const handleClose = () => {
    popupsActions.setShowPlanPopupChecker(false);
    onClose();
  };

  const handleCheckout = async () => {
    try {
      if (error) {
        throw new Error(error);
      }
      if (!playerInfo) {
        popupsActions.setShowRegisterPopup(true);
        return;
      }
      if (!isValidPhoneNumber(phone)) {
        throw new Error('Invalid phone number');
      }

      if (!selectedPlan) throw new Error('No plan selected');
      const { firstName, lastName, email, phoneNumber, id } = playerInfo;
      if (!firstName || !lastName || !email || !phoneNumber || !id) {
        throw new Error('Missing required billing data fields');
      }

      if (!selectedPlan.amount) {
        throw new Error('No amount specified');
      }
      if (!currentParticipation?.id)
        throw new Error('Participation is not found');

      if (amountToPay === 0 && !discountData)
        throw new Error('Amount to pay is not found');
      setIsLoading(true);
      popupsActions.setShowPlanPopupChecker(false);

      let data: any = {
        amount: String(amountToPay),
        currency: selectedPlan.currency || 'EGP',
        paymentMethod: selectedMethod as (typeof PaymentMethods)[number],
        participation: { connect: { id: currentParticipation?.id } },
        plan: { connect: { id: selectedPlan.id } },
        player: { connect: { id } },
      };

      if (discountData) {
        const foundPlan = discountData.paymentPlans.find(
          (plan) => plan.id === selectedPlan.id
        );
        if (foundPlan) {
          data = {
            ...data,
            discount: { connect: { id: discountData.id } },
          };
        }
      }
      const shallowPaymentResponse = await createPayment({
        variables: {
          data,
        },
      });
      const paymentId = shallowPaymentResponse?.data?.createPayment?.id;
      if (!paymentId) {
        throw new Error('Failed to create plan payment');
      }

      const paymentData: PaymentData = {
        amount: Number(amountToPay) || 0,
        currency: selectedPlan.currency || 'EGP',
        paymentMethod: selectedMethod as (typeof PaymentMethods)[number],
        merchant_order_id: paymentId,
        billingData: {
          first_name: firstName,
          last_name: lastName,
          email,
          phone_number: phone,
        },
      };
      const response = await generatePayment(paymentData);
      const { paymentUrl } = response.data;
      if (!paymentUrl) {
        throw new Error('Failed to generate payment URL');
      }
      onClose();
      window.location.href = paymentUrl;
    } catch (error) {
      console.log(error);
      toast({
        title: error.message,
        status: 'error',
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={isLoading ? () => {} : handleClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Payment Method
            </AlertDialogHeader>
            <Box as="hr" />

            <AlertDialogBody>
              {isLoading ? (
                <Box>
                  <HStack justifyContent={'center'}>
                    <Spinner size="sm" />
                    <Text>Please wait, redirecting to your payment link</Text>
                  </HStack>
                </Box>
              ) : (
                <Box>
                  <Box>
                    Please choose your payment method to proceed with your
                    payment.{' '}
                  </Box>
                  <HStack
                    {...group}
                    my="30px"
                    justifyContent={'center'}
                    pointerEvents={isLoading ? 'none' : 'auto'}
                  >
                    {PaymentMethods.map((value) => {
                      const radio = getRadioProps({ value });
                      return (
                        <RadioCard key={value} {...radio}>
                          {value}
                        </RadioCard>
                      );
                    })}
                  </HStack>

                  {selectedMethod === 'Mobile Wallet' ? (
                    <Text
                      textAlign="center"
                      fontSize="sm"
                      color="red.500"
                      mb="20px"
                      mt="-10px"
                    >
                      Please make sure the mobile number has registered wallet.
                    </Text>
                  ) : null}

                  <Box>
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      placeholder="Enter a valid phone number"
                      value={phone}
                      pattern="[0-9]{11}"
                      onChange={(e) => setPhone(e.target.value)}
                      disabled={isLoading}
                      mb="20px"
                    />
                  </Box>

                  <Box rounded="10px" bg="gray.100" p="20px 20px">
                    <Center fontWeight={'bold'} mb="15px">
                      Confirmation
                    </Center>
                    {confirmation}
                  </Box>
                </Box>
              )}

              <Box my="20px">
                <Box
                  as="a"
                  href="https://paymob.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                  display="flex"
                  alignItems="center"
                  justifyContent={'center'}
                >
                  <Text as="span" fontSize="sm">
                    All Payments powered by
                  </Text>
                  <Image src={PaymobLogo} width="90px" ml="10px" />
                </Box>
              </Box>
            </AlertDialogBody>
            <Box as="hr" />
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={handleClose}
                isDisabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                ref={cancelRef}
                onClick={handleCheckout}
                bg={greenishColor}
                isDisabled={isLoading}
                isLoading={isLoading}
                color="white"
                _hover={{ bg: 'teal.400' }}
                ml="20px"
              >
                {'Proceed to Checkout'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default PaymentMethodsPopup;
