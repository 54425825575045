import { PlayerInterface } from '../containers/Player';
import { Plan } from '../containers/Pricing/PricingPlans';
import { Participation } from '../hooks/useLeaguesState';

const playerPositions = [
  'GK',
  'SW',
  'CB',
  'RB',
  'RWB',
  'LB',
  'CDM',
  'CM',
  'RM',
  'LM',
  'LW',
  'RW',
  'CF',
  'ST',
] as const;
export type PlayerPosition = (typeof playerPositions)[number];
export type registrationType =
  | 'TEAM_REGISTRATION'
  | 'PLAYER_REGISTRATION'
  | 'TRANSFER_MARKET';

export interface MatchInterface {
  isMatchLive: boolean;
  inMatchTime: string;
  isMatchEnded: boolean;
  id: string;
  stage: string;
  matchDate: string;
  firstHalfStartTime: string;
  secondHalfStartTime: string;
  homeTeamScore: number;
  awayTeamScore: number;
  homeTeamExtraPoints: number;
  awayTeamExtraPoints: number;
  group: string;
  type: string;
  lineupCountPerTeam: number;
  season: Season;
  videosUrls: string;
  homeTeam: {
    id: string;
    name: string;
    group: string;
    teams: Array<{
      id: string;
      name: string;
      teamLogo: {
        url: string;
      };
      mascotLogo: string;
    }>;
  };
  awayTeam: {
    id: string;
    name: string;
    group: string;
    teams: Array<{
      id: string;
      name: string;
      teamLogo: {
        url: string;
      };
      mascotLogo: string;
    }>;
  };
  lineUp: Array<{
    team: string;
    type: string;
    name: string;
    isHomeTeam: boolean;
    isSub: boolean;
    isCaptain: boolean;
    position: string;
    playerPhoto: string;
  }>;
  actionDetails: Array<{
    team: string;
    type: string;
    time: string;
    name: string;
    isHomeTeam: boolean;
    isFirstHalf: boolean;
    isOwnGoal: boolean;
    isInjurySub: boolean;
    isDirectRedCard: boolean;
    assistName: string;
    isPenaltyGoal: boolean;
  }>;
  penalties: Array<{
    team: string;
    type: string;
    penaltyNumber: string;
    isHomeTeam: boolean;
    name: string;
  }>;
  statistics: Array<{
    homeTeam: string;
    awayTeam: string;
    type: string;
  }>;
  competition: {
    name: string;
  };
  stadium: string;
  referee: string;
  homeTeamFormation: string;
  awayTeamFormation: string;
  homeTeamFairPlayPoints: boolean;
  awayTeamFairPlayPoints: boolean;
}

export interface LeaugeInfo {
  isRegistrationOn: boolean;
  isTeamRegistrationOn: boolean;
  slogan: string;
  subtitle: string;
  title: string;
  title2: string;
  leagueRules: {
    document: any;
  };
  seasonDetails: {
    document: any;
  };
}

export interface Season {
  endDate: string;
  id: string;
  name: string;
  seasonNumber: number;
  startDate: string;
}

export interface Competition {
  id: string;
  name: string;
  seasons: Array<Season>;
  disableRegistration: boolean;
  disableEdits: boolean;
  isTournament: boolean;
  minPlayersCount: number;
  maxPlayersCount: number;
  isOpenForPayment: boolean;
  isPublic: boolean;
  currentSeason: Season;
  logo: {
    url: string;
  } | null;
  baseColor: string;
  textColor: string;
  mainSportType: string;
  leagueRules: {
    document: any;
  };
  seasonDetails: {
    document: any;
  };
}
export interface ApplicationDataType {
  seasons: Array<Season>;
  competitions: Array<Competition>;
}

export interface ScoreSheet {
  isHomeTeam: boolean;
  homeTeam: {
    name: string;
    id: string;
    teams: [
      {
        name: string;
      }
    ];
  };
  awayTeam: {
    name: string;
    id: string;
    teams: [
      {
        name: string;
      }
    ];
  };
  homeTeamId: string;
  awayTeamId: string;
  homeTeamScore: number;
  awayTeamScore: number;
}

export interface TableType {
  team: string;
  partId: string;
  MP: string;
  W: string;
  L: string;
  D: string;
  GF: string;
  GA: string;
  GD: string;
  FP: string;
  PTS: string;
  h2h: number;
  scoreSheet: Array<ScoreSheet>;
  participation?: Participation;
}
export interface TopPlayerType {
  name: string;
  team: string;
  goals: string;
  assists: string;
  photo: string;
  position: string;
  playerId: string;
  inGoals: string;
  PLD: string;
  teamData?: Participation;
  playerPhoto: string;
}

export interface TopGoaliesType extends TopPlayerType {
  inGoals: string;
}

export interface TopCardsType extends TopPlayerType {
  redCard: string;
  yellowCard: string;
}

export interface TopGoalieTest extends TopPlayerType {
  inGoals: string;
}

export interface TopPlayer extends TopPlayerType {
  redCard: string;
  yellowCard: string;
  playerPhoeo: string;
  matchesIds: string[];
}
export interface LeagueTableType {
  competition: Competition;
  season: Season;
  table: Array<TableType>;
  topScorer: Array<TopPlayerType>;
  topAssist: Array<TopPlayerType>;
  topCard: Array<TopCardsType>;
  topGoalieTest: Array<TopGoalieTest>;
  topGoalie: Array<TopGoaliesType>;
  topPlayer: Array<TopPlayer>;
}

export interface Payment {
  id: string;
  amount: string;
  currency: string;
  isFulfilled: boolean;
  pending: boolean;
  success: boolean;
  createdAt: string;
  orderId: string;
  paymentMethod: string;
  txId: string;
  participation: Participation;
  plan: Plan;
  player: PlayerInterface;
}

export const PaymentMethods = ['Card', 'Mobile Wallet'] as const;

export interface PaymentData {
  amount: number;
  currency: string;
  paymentMethod: (typeof PaymentMethods)[number];
  merchant_order_id: string;
  billingData: BillingData;
}
export interface BillingData {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}
