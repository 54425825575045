import { Box, Select, Stack } from '@chakra-ui/react';
import Sidebar from '../Navigation';
import HeadingTitle from '../../components/HeadingTitle';
import { useStoreActions, useStoreState } from '../../store';
import { useEffect, useMemo, useState } from 'react';
import { MAIN_LEAGUE } from '../../utils/constants';
import useLeaguesState from '../../hooks/useLeaguesState';
import {
  lowerCaseNoSpaces,
  upperCaseFirstLetterWithSpaces,
} from '../../utils/helper';
import { useNavigate, useParams } from 'react-router-dom';
import PricingPlans from './PricingPlans';
import Footer from '../Footer';

const Pricing = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { comp } = params;
  const { userModel: userState } = useStoreState((state) => state);
  const [selectedCompetition, setSelectedCompetition] = useState(MAIN_LEAGUE);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);
  const { publicCompetitions } = useLeaguesState();

  const currentCompetition = useMemo(() => {
    const foundComp = publicCompetitions?.find(
      (x) => x.name.toLowerCase() === selectedCompetition?.toLowerCase()
    );
    if (foundComp) return foundComp;
    return publicCompetitions?.find((x) => x.name === 'AL Elite');
  }, [publicCompetitions, selectedCompetition]);

  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);

  useEffect(() => {
    if (comp) {
      setSelectedCompetition(upperCaseFirstLetterWithSpaces(comp));
    } else {
      navigate('/payments/al-elite');
    }
  }, [comp, navigate]);

  return (
    <Sidebar>
      <Stack
        bg="white"
        minH="100vh"
        pt="130px"
        display="flex"
        justifyContent={'space-between'}
      >
        <Box>
          <Box textAlign={'center'} mb="50px">
            <HeadingTitle>{'Payments'}</HeadingTitle>
          </Box>
          <Box textAlign={'center'}>
            <Box maxW="350px" m="0 auto 20px">
              <Select
                fontWeight={'bold'}
                value={selectedCompetition}
                onChange={(e) => {
                  console.log(lowerCaseNoSpaces(e.target.value));
                  navigate(`/payments/${lowerCaseNoSpaces(e.target.value)}`);
                }}
              >
                <option value="">Choose League</option>
                {publicCompetitions?.map((comp) => (
                  <option
                    key={comp.id}
                    value={comp.name}
                    disabled={
                      comp.disableRegistration ||
                      !currentCompetition?.isOpenForPayment
                    }
                  >
                    {comp.name}
                  </option>
                ))}
              </Select>
            </Box>

            <PricingPlans
              compId={currentCompetition?.id}
              seasonId={currentCompetition?.lastSeason?.id}
              isRegistrationDisabled={
                currentCompetition?.disableRegistration ||
                !currentCompetition?.isOpenForPayment
              }
              compName={currentCompetition?.name}
            />
          </Box>
        </Box>
        <Footer marginTop="0" />
      </Stack>
    </Sidebar>
  );
};

export default Pricing;
